:root {
  --semantic-brand-yellow: #ffc600;
  --semantic-brand-teal: #068071;
  --semantic-brand-amber: #ed8b00;
  --semantic-brand-black: #1a1a1a;
  --semantic-brand-gray: grey;
  --semantic-brand-white: #fff;
  --semantic-connotation-success: #068071;
  --semantic-connotation-info: #1880b4;
  --semantic-connotation-progress: #ffc600;
  --semantic-connotation-warning: #ed8b00;
  --semantic-connotation-error: #cc3833;
  --semantic-link-default: #068071;
  --semantic-link-hover: #ed8b00;
  --semantic-link-active: #c70;
  --semantic-gradient-primary: linear-gradient(90deg, #ffc600, #ed8b00);
  --light-elevation-plus-1: #1a1a1a0a;
  --light-elevation-plus-2: #1a1a1a14;
  --light-elevation-plus-3: #1a1a1a1e;
  --light-elevation-plus-4: #1a1a1a28;
  --light-elevation-plus-5: #1a1a1a33;
  --light-background-surface: #fff;
  --light-background-canvas: #f2f2f2;
  --light-background-surface-on-media: #ffffffe0;
  --light-background-card: #fff;
  --light-background-footer: #333;
  --light-background-overlay: #1a1a1af4;
  --light-border-primary: #1a1a1a;
  --light-border-secondary: #666;
  --light-border-secondary-on-media: #1a1a1aa3;
  --light-border-tertiary: #b3b3b3;
  --light-border-quaternary-on-media: #1a1a1a28;
  --light-border-quaternary: #e6e6e6;
  --light-icon-primary: #1a1a1a;
  --light-icon-primary-on-media: #1a1a1acc;
  --light-icon-secondary: #666;
  --light-icon-secondary-on-media: #1a1a1aa3;
  --light-icon-tertiary: #b3b3b3;
  --light-icon-tertiary-on-media: #1a1a1a51;
  --light-icon-quaternary: #e6e6e6;
  --light-icon-quaternary-on-media: #1a1a1a28;
  --light-text-primary: #1a1a1a;
  --light-text-primary-on-media: #1a1a1acc;
  --light-text-secondary: #666;
  --light-text-secondary-on-media: #1a1a1aa3;
  --light-text-tertiary: #b3b3b3;
  --light-text-tertiary-on-media: #1a1a1a51;
  --light-text-quaternary-on-media: #1a1a1a28;
  --dark-elevation-plus-1: #ffffff14;
  --dark-elevation-plus-2: #ffffff1e;
  --dark-elevation-plus-3: #ffffff28;
  --dark-elevation-plus-4: #ffffff33;
  --dark-elevation-plus-5: #ffffff3d;
  --dark-background-canvas: #333;
  --dark-background-surface: #1a1a1a;
  --dark-background-surface-on-media: #1a1a1ae0;
  --dark-background-card: #333;
  --dark-background-footer: #fff;
  --dark-background-overlay: #1a1a1af4;
  --dark-border-primary: #fff;
  --dark-border-secondary: #b3b3b3;
  --dark-border-secondary-on-media: #ffffffa3;
  --dark-border-tertiary: #666;
  --dark-border-quaternary-on-media: #ffffff28;
  --dark-icon-primary: #fff;
  --dark-icon-primary-on-media: #ffffffcc;
  --dark-icon-secondary: #b3b3b3;
  --dark-icon-secondary-on-media: #ffffffa3;
  --dark-icon-tertiary: #666;
  --dark-icon-tertiary-on-media: #ffffff51;
  --dark-icon-quaternary: #4d4d4d;
  --dark-icon-quaternary-on-media: #ffffff28;
  --dark-text-primary: #fff;
  --dark-text-primary-on-media: #ffffffcc;
  --dark-text-secondary: #b3b3b3;
  --dark-text-secondary-on-media: #ffffffa3;
  --dark-text-tertiary: #666;
  --dark-text-tertiary-on-media: #ffffff51;
  --dark-text-quaternary-on-media: #ffffff28;
  --core-neutral-0: #fff;
  --core-neutral-50: #f2f2f2;
  --core-neutral-100: #e6e6e6;
  --core-neutral-200: #ccc;
  --core-neutral-300: #b3b3b3;
  --core-neutral-400: #999;
  --core-neutral-500: grey;
  --core-neutral-600: #666;
  --core-neutral-700: #4d4d4d;
  --core-neutral-800: #333;
  --core-neutral-900: #1a1a1a;
  --core-red-100: #f5d7d6;
  --core-red-200: #ebafad;
  --core-red-300: #e08885;
  --core-red-400: #d6605c;
  --core-red-500: #cc3833;
  --core-red-600: #a32d29;
  --core-red-700: #7a221f;
  --core-orange-100: #ffdfcc;
  --core-orange-200: #ffbe99;
  --core-orange-300: #ff9e66;
  --core-orange-400: #ff7e33;
  --core-orange-500: #ff5e00;
  --core-orange-600: #cc4b00;
  --core-amber-100: #ffeacc;
  --core-amber-200: #ffd599;
  --core-amber-300: #ffbf66;
  --core-amber-400: #fa3;
  --core-amber-500: #ff9500;
  --core-amber-600: #ed8b00;
  --core-amber-700: #c70;
  --core-yellow-100: #fff4cc;
  --core-yellow-200: #ffe999;
  --core-yellow-300: #ffde66;
  --core-yellow-400: #ffd333;
  --core-yellow-500: #ffc600;
  --core-yellow-600: #cc9e00;
  --core-teal-100: #dcf4ef;
  --core-teal-200: #b8e6de;
  --core-teal-300: #93d8c3;
  --core-teal-400: #6fcabd;
  --core-teal-500: #4bbcac;
  --core-teal-600: #26ae9c;
  --core-teal-700: #02a08b;
  --core-teal-800: #068071;
  --core-teal-900: #036154;
  --core-blue-100: #d2ecf9;
  --core-blue-200: #a5d9f3;
  --core-blue-300: #78c6ed;
  --core-blue-400: #4bb3e7;
  --core-blue-500: #1fa0e0;
  --core-blue-600: #1880b4;
  --core-blue-700: #126087;
  --core-blue-800: #0c405a;
  --core-purple-100: #e0ddee;
  --core-purple-200: #c1bbdd;
  --core-purple-300: #a299cc;
  --core-purple-400: #8477bb;
  --core-purple-500: #6555aa;
  --core-purple-600: #514488;
  --core-purple-700: #3c3366;
  background-color: var(--light-background-canvas);
  --dropshadow-container: 0rem 0rem 0.75rem 0rem rgba(26, 26, 26, 0.16);
  --dropshadow-hover: 0rem 0.25rem 0.75rem 0rem rgba(26, 26, 26, 0.12);
  --dropshadow-modal: 0rem 0.75rem 1rem -0.5rem rgba(26, 26, 26, 0.16), 0rem 1.5rem 2.5rem 2.5rem rgba(26, 26, 26, 0.16),
    0rem 0.5rem 3rem 0.5rem rgba(26, 26, 26, 0.16);
  --dropshadow-minimal: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
  color: var(--light-text-primary, #1a1a1a);
  font-size: 1rem;
  line-height: 1.5rem;
  --font-freightdisp-pro: 'FreightDisp Pro', serif;
  --font-proxima-nova: 'Proxima Nova', sans-serif;
  --font-source-code-pro: 'Source Sans Pro', monospace;
  --desktop-heading-1: 400 2.5rem/2.5rem var(--font-freightdisp-pro);
  --desktop-heading-2: 600 2rem/2.25rem var(--font-proxima-nova);
  --desktop-heading-3: 400 1.5rem/1.75rem var(--font-proxima-nova);
  --desktop-heading-4: 600 1.125rem/1.5rem var(--font-proxima-nova);
  --desktop-heading-5: 400 1.125rem/1.5rem var(--font-proxima-nova);
  --desktop-heading-6: 700 0.75rem/1.25rem var(--font-proxima-nova);
  --desktop-body-caption: 400 0.75rem/1rem var(--font-proxima-nova);
  --desktop-body-emphasize: 600 0.75rem/1rem var(--font-proxima-nova);
  --desktop-body-focused: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --desktop-body-highlight: 600 0.75rem/1rem var(--font-proxima-nova);
  --desktop-body-interactive: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --desktop-body-link: 400 1rem/1.5rem var(--font-proxima-nova);
  --desktop-body-number: 400 1rem/1.5rem var(--font-source-code-pro);
  --desktop-body-paragraph: 400 1rem/1.5rem var(--font-proxima-nova);
  --desktop-body-strong: 600 1rem/1.5rem var(--font-proxima-nova);
  --desktop-fine-strong: 600 0.625rem/1rem var(--font-proxima-nova);
  --tablet-heading-1: 400 2rem/2rem var(--font-freightdisp-pro);
  --tablet-heading-2: 600 1.5rem/1.75rem var(--font-proxima-nova);
  --tablet-heading-3: 400 1.25rem/1.5rem var(--font-proxima-nova);
  --tablet-heading-4: 600 1.125rem/1.5rem var(--font-proxima-nova);
  --tablet-heading-5: 400 1.125rem/1.5rem var(--font-proxima-nova);
  --tablet-heading-6: 700 0.75rem/1.25rem var(--font-proxima-nova);
  --tablet-body-caption: 400 0.75rem/1rem var(--font-proxima-nova);
  --tablet-body-emphasize: 600 0.75rem/1rem var(--font-proxima-nova);
  --tablet-body-focused: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --tablet-body-highlight: 600 0.75rem/1rem var(--font-proxima-nova);
  --tablet-body-interactive: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --tablet-body-link: 400 0.875rem/1.25rem var(--font-proxima-nova);
  --tablet-body-number: 400 0.875rem/1.25rem var(--font-source-code-pro);
  --tablet-body-paragraph: 400 0.875rem/1.25rem var(--font-proxima-nova);
  --tablet-body-strong: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --tablet-fine-strong: 600 0.625rem/1rem var(--font-proxima-nova);
  --mobile-heading-1: 400 2.5rem/2.5rem var(--font-freightdisp-pro);
  --mobile-heading-2: 600 2rem/2.25rem var(--font-proxima-nova);
  --mobile-heading-3: 400 2.5rem/2.75rem var(--font-proxima-nova);
  --mobile-heading-4: 600 1.125rem/1.5rem var(--font-proxima-nova);
  --mobile-heading-5: 400 1.125rem/1.5rem var(--font-proxima-nova);
  --mobile-heading-6: 700 0.75rem/1.25rem var(--font-proxima-nova);
  --mobile-body-caption: 400 0.75rem/1rem var(--font-proxima-nova);
  --mobile-body-emphasize: 600 0.75rem/1rem var(--font-proxima-nova);
  --mobile-body-focused: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --mobile-body-highlight: 600 0.75rem/1rem var(--font-proxima-nova);
  --mobile-body-interactive: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --mobile-body-link: 400 0.875rem/1.25rem var(--font-proxima-nova);
  --mobile-body-number: 400 0.875rem/1.25rem var(--font-source-code-pro);
  --mobile-body-paragraph: 400 0.875rem/1.25rem var(--font-proxima-nova);
  --mobile-body-strong: 600 0.875rem/1.25rem var(--font-proxima-nova);
  --mobile-fine-strong: 600 0.625rem/1rem var(--font-proxima-nova);
  --base-animation-timing-function: ease-in-out;
  --base-animation-duration: 200ms;
  --base-shimmer-background: linear-gradient(90deg, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  --base-shimmer-animation: 2.2s linear infinite forwards shimmer;
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  to {
    background-position: 1200px 0;
  }
}
:root {
  --border-radius-small: 0.25rem;
  --info-vertical-padding: 0.25rem;
  --icon-dimension: 1.5rem;
  --input-inner-padding: 0.5rem;
  --link-selected-border-bottom: 0.25rem solid var(--core-yellow-500, pink);
  --interactive-element-height: 2.5rem;
}
/*@font-face {*/
/*  font-family: FreightDisp Pro;*/
/*  src:*/
/*    local('FreightDisp Pro'),*/
/*    url(../fonts/freigdispromed.otf) format('opentype');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 100;*/
/*  src:*/
/*    url(../fonts/ProximaNovaT-Thin.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNovaT-Thin.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 100;*/
/*  src:*/
/*    url(../fonts/ProximaNova-ThinIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-ThinIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 300;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Light.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Light.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 300;*/
/*  src:*/
/*    url(../fonts/ProximaNova-LightIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-LightIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Regular.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Regular.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 400;*/
/*  src:*/
/*    url(../fonts/ProximaNova-RegularIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-RegularIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 500;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Medium.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Medium.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 500;*/
/*  src:*/
/*    url(../fonts/ProximaNova-MediumIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-MediumIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 600;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Semibold.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Semibold.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 600;*/
/*  src:*/
/*    url(../fonts/ProximaNova-SemiboldIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-SemiboldIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 700;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Bold.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Bold.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 700;*/
/*  src:*/
/*    url(../fonts/ProximaNova-BoldIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-BoldIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 800;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Extrabld.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Extrabld.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 800;*/
/*  src:*/
/*    url(../fonts/ProximaNova-ExtrabldIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-ExtrabldIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: normal;*/
/*  font-weight: 900;*/
/*  src:*/
/*    url(../fonts/ProximaNova-Black.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-Black.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-display: swap;*/
/*  font-family: Proxima Nova;*/
/*  font-style: italic;*/
/*  font-weight: 900;*/
/*  src:*/
/*    url(../fonts/ProximaNova-BlackIt.woff2) format('woff2'),*/
/*    url(../fonts/ProximaNova-BlackIt.woff) format('woff');*/
/*}*/
/*@font-face {*/
/*  font-family: Source Sans Pro;*/
/*  src:*/
/*    local('Source Sans Pro'),*/
/*    url(../fonts/SourceSansPro.woff2) format('woff2');*/
/*}*/
.loading-dual-ring {
  display: inline-block;
  height: 5rem;
  width: 5rem;
}
.loading-dual-ring:after {
  animation: loading-dual-ring 1.2s linear infinite;
  border: 0.375rem solid var(--core-neutral-900);
  border-color: var(--core-neutral-900) transparent var(--core-neutral-900) transparent;
  border-radius: 50%;
  content: ' ';
  display: block;
  height: 4rem;
  margin: 0.5rem;
  width: 4rem;
}
@keyframes loading-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.shimmer {
  animation: var(--base-shimmer-animation);
  background: var(--base-shimmer-background);
  background-size: 1200px 100%;
}
.shimmer * {
  visibility: hidden;
}
.base-heading-1 {
  font: var(--mobile-heading-1);
  @media (min-width: 1024px) {
    font: var(--tablet-heading-1);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-heading-1);
  }
}
.base-heading-2 {
  font: var(--mobile-heading-2);
  @media (min-width: 1024px) {
    font: var(--tablet-heading-2);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-heading-2);
  }
}
.base-heading-3 {
  font: var(--mobile-heading-3);
  @media (min-width: 1024px) {
    font: var(--tablet-heading-3);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-heading-3);
  }
}
.base-heading-4 {
  font: var(--mobile-heading-4);
  @media (min-width: 1024px) {
    font: var(--tablet-heading-4);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-heading-4);
  }
}
.base-heading-5 {
  font: var(--mobile-heading-5);
  @media (min-width: 1024px) {
    font: var(--tablet-heading-5);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-heading-5);
  }
}
.base-heading-6 {
  font: var(--mobile-heading-6);
  letter-spacing: 0.125rem;
  @media (min-width: 1024px) {
    font: var(--tablet-heading-6);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-heading-6);
  }
}
.base-body-caption {
  color: var(--light-text-secondary);
  font: var(--mobile-body-caption);
  @media (min-width: 1024px) {
    font: var(--tablet-body-caption);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-caption);
  }
}
.base-body-emphasize {
  font: var(--mobile-body-emphasize);
  @media (min-width: 1024px) {
    font: var(--tablet-body-emphasize);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-emphasize);
  }
}
.base-body-focused {
  font: var(--mobile-body-focused);
  @media (min-width: 1024px) {
    font: var(--tablet-body-focused);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-focused);
  }
}
.base-body-highlight {
  font: var(--mobile-body-highlight);
  @media (min-width: 1024px) {
    font: var(--tablet-body-highlight);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-highlight);
  }
}
.base-body-interactive {
  font: var(--mobile-body-interactive);
  @media (min-width: 1024px) {
    font: var(--tablet-body-interactive);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-interactive);
  }
}
.base-body-link {
  color: var(--semantic-link-default);
  font: var(--mobile-body-link);
  text-decoration: underline;
}
.base-body-link:hover {
  color: var(--semantic-link-hover);
}
.base-body-link:active {
  color: var(--semantic-link-active);
}
.base-body-link {
  @media (min-width: 1024px) {
    font: var(--tablet-body-link);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-link);
  }
}
.base-body-number {
  font: var(--mobile-body-number);
  @media (min-width: 1024px) {
    font: var(--tablet-body-number);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-number);
  }
}
.base-body-paragraph {
  font: var(--mobile-body-paragraph);
  @media (min-width: 1024px) {
    font: var(--tablet-body-paragraph);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-paragraph);
  }
}
.base-body-strong {
  font: var(--mobile-body-strong);
  @media (min-width: 1024px) {
    font: var(--tablet-body-strong);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-strong);
  }
}
.base-fine-strong {
  font: var(--mobile-fine-strong);
  @media (min-width: 1024px) {
    font: var(--tablet-fine-strong);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-fine-strong);
  }
}
.material-symbols-sharp {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
.material-symbols-sharp.filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
.srsa-badge {
  position: relative;
}
.srsa-badge .badge-content {
  font: var(--mobile-body-highlight, 3rem cursive);
  @media (min-width: 1024px) {
    font: var(--tablet-body-highlight, 3rem cursive);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-highlight, 3rem cursive);
  }
  background-color: var(--light-icon-quaternary, pink);
  border-radius: 8rem;
  color: var(--light-text-primary, purple);
  height: 1rem;
  min-width: 1rem;
  padding: 0 0.25rem;
  position: absolute;
  right: 0.125rem;
  text-align: center;
  top: 0.125rem;
}
.srsa-badge .badge-content.badge-inline {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin-left: 0.25rem;
  position: relative;
  right: 0;
  top: 0;
}
.srsa-badge .badge-content.badge-small {
  height: 0.5rem;
  min-width: 0.5rem;
  right: 0;
  top: 0;
}
.srsa-badge .badge-content.badge-single-digit {
  min-width: 0;
}
.srsa-badge .badge-content.badge-progress {
  background-color: var(--semantic-connotation-progress, pink);
}
.srsa-badge .badge-content.badge-warning {
  background-color: var(--semantic-connotation-warning, pink);
  color: var(--dark-text-primary, purple);
}
.srsa-badge .badge-content.badge-success {
  background-color: var(--semantic-connotation-success, pink);
  color: var(--dark-text-primary, purple);
}
.srsa-badge .badge-content.badge-error {
  background-color: var(--semantic-connotation-error, pink);
  color: var(--dark-text-primary, purple);
}
.srsa-badge .badge-content.shimmer {
  color: transparent;
}
.srsa-icon-rotation {
  transform: rotate(0deg);
  transition: transform var(--base-animation-duration) var(--base-animation-timing-function);
}
.srsa-icon-rotation.rotate {
  transform: rotate(180deg);
}
.srsa-button {
  align-items: center;
  border: 1px solid var(--light-border-primary);
  border-radius: 0.25rem;
  display: inline-flex;
  font: var(--desktop-body-focused);
  height: var(--interactive-element-height, 1rem);
  opacity: 100%;
  padding: 0.5rem;
  @media (min-width: 1024px) {
    font: var(--tablet-body-focused);
  }
  @media (min-width: 1280px) {
    font: var(--desktop-body-focused);
  }
}
.srsa-button.shimmer {
  border: none;
}
.srsa-button.anchor {
  border: 1px solid var(--light-border-primary);
}
.srsa-button.primary {
  background-color: var(--core-yellow-500, #ff0);
}
.srsa-button.destructive {
  background-color: var(--core-red-600, red);
  border: none;
  color: var(--core-neutral-0, #fff);
  font: var(--desktop-body-interactive);
}
.srsa-button:hover {
  background-color: var(--core-yellow-400);
  box-shadow: var(--dropshadow-hover);
  color: var(--core-neutral-900);
  font: var(--desktop-body-interactive);
}
.srsa-button:focus-visible .button-label {
  text-decoration: underline;
}
.srsa-button:active {
  background-color: var(--core-amber-600);
}
.srsa-button:disabled {
  background-color: transparent;
  border: 1px solid var(--light-border-quaternary-on-media);
  box-shadow: none;
  color: var(--light-border-quaternary-on-media);
}
.srsa-button.quaternary,
.srsa-button.tertiary {
  border: none;
}
.srsa-button.quaternary:hover {
  background-color: transparent;
  box-shadow: none;
}
.srsa-button.quaternary:active {
  background-color: transparent;
  color: var(--light-border-quaternary-on-media);
}
.srsa-button.quaternary:disabled {
  background-color: transparent;
}
.srsa-button .icon-leading {
  margin-right: 0.3rem;
}
.srsa-button .button-label {
  text-align: center;
  width: 100%;
}
.srsa-button .icon-trailing {
  margin-left: 0.3rem;
}
