/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  //min-width: 1200px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  //min-width: 1200px;
}

.file-action {
  text-align: center;
  padding: 3rem 1rem;
}

.progress-bar {
  text-align: left;
  font-size: 0.75rem;
  padding: 1rem 0;
}

#app_init_error__backdrop {
    background-color: #33333311;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    width: 100vw;

    #app_init_error__card {
        background-color: #FFFFFF;
        border-radius: 4px;
        max-width: 450px;

        #app_init_error__card-header {
            background-color: #FF0000;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            height: 4rem;
            padding: 1rem;

            #app_init_error__title {
                color: #FFFFFF;
            }
        }

        #app_init_error__card-content {
            height: calc(100% - 4rem);
            padding: 1rem;
            width: 100%;
        }
    }
}

.dialog__no-padding .mat-mdc-dialog-container {
    padding: 0;
}
