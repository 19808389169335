@font-face {
    font-family: FreightDisplayProMedium;
    src: url("./freight-display-pro-medium.woff") format("opentype");
}

@font-face {
    font-family: FreightDisplayProMedium2;
    src: url("./freight-display-pro-medium.woff2") format("opentype");
}

@font-face {
    font-family: ProximaNovaBold;
    src: url("./proxima-nova-bold.woff") format("opentype");
}

@font-face {
    font-family: ProximaNovaBold2;
    src: url("./proxima-nova-bold.woff2") format("opentype");
}

@font-face {
    font-family: ProximaNovaRegular;
    src: url("./proxima-nova-regular.woff") format("opentype");
}

@font-face {
    font-family: ProximaNovaRegular2;
    src: url("./proxima-nova-regular.woff2") format("opentype");
}

@font-face {
    font-family: ProximaNovaSemibold;
    src: url("./proxima-nova-semibold.woff") format("opentype");
}

@font-face {
    font-family: ProximaNovaSemibold2;
    src: url("./proxima-nova-semibold.woff2") format("opentype");
}

@font-face {
    font-family: SourceSansRegular;
    src: url("./source-sans-regular.woff") format("opentype");
}

@font-face {
    font-family: SourceSansRegular2;
    src: url("./source-sans-regular.woff2") format("opentype");
}
