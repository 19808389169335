.mat-drawer-container,
.mat-mdc-select-value,
.mat-mdc-outlined-button .mdc-button__label,
.profile-menu .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.p-dropdown-label,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.mat-mdc-select-value-text,
.mat-mdc-paginator-page-size-label,
.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text,
.mat-mdc-option.mdc-list-item,
.mat-mdc-paginator-range-label,
.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input,
.mat-form-field-hide-placeholder .mat-mdc-select-placeholder,
.mdc-text-field--filled .mdc-floating-label mat-label,
mat-hint,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-mdc-checkbox .mdc-form-field .mdc-label,
.mat-mdc-form-field-infix .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-mdc-form-field-error,
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label,
.mdc-data-table__row:last-child .mat-mdc-cell,
.mat-mdc-cell,
.mat-mdc-paginator,
.mat-mdc-paginator-container,
.mat-mdc-paginator .mat-mdc-select,
.mat-mdc-select,
.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text,
.mat-mdc-tab .mdc-tab__content,
.p-dropdown.p-dropdown-clearable .p-dropdown-label,
.mat-mdc-unelevated-button .mdc-button__label {
    letter-spacing: 0;
}
